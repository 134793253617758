import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import BlockRouter from '../components/BlockRouter';
import GradientWrapper from '../components/GradientWrapper';
import HelmetMichelberger from '../components/HelmetMichelberger';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';


const GenericPage = ({ data, location, pageContext }) => {
  const { languageSwitch } = pageContext;
  const {
    body, gradient, name, seoMetaTags,
  } = data.allDatoCmsGenericPage.edges[0].node;
  const footerData = data.allDatoCmsFooter.edges[0].node;

  return (
    <Layout languageSwitch={languageSwitch} location={location}>
      <GradientWrapper footerData={footerData} gradient={gradient} languageSwitch={languageSwitch}>
        <HelmetMichelberger seo={seoMetaTags} />
        <PageTitle>{name}</PageTitle>
        <For each="block" index="index" of={body}>
          <BlockRouter key={block.id} block={block} index={index} />
        </For>
      </GradientWrapper>
    </Layout>
  );
};

GenericPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default GenericPage;

export const query = graphql`
  query GenericPage($locale: String!, $slug: String!) {
    allDatoCmsGenericPage(filter: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      edges {
        node {
          gradient {
            color {
              hex
            }
          }
          name
          slug
          ...GatsbyDatoCmsGenericPageBody
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    }
    allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...footerFields
        }
      }
    }
  }
`;
